// Cursors
.c-hand {
  cursor: pointer;
}

.c-move {
  cursor: move;
}

.c-zoom-in {
  cursor: zoom-in;
}

.c-zoom-out {
  cursor: zoom-out;
}

.c-not-allowed {
  cursor: not-allowed;
}

.c-auto {
  cursor: auto;
}
