// Modals
.modal {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: $layout-spacing;
  position: fixed;
  right: 0;
  top: 0;

  &:target,
  &.active {
    display: flex;
    opacity: 1;
    z-index: $zindex-4;

    .modal-overlay {
      background: rgba($bg-color, .75);
      bottom: 0;
      cursor: default;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .modal-container {
      animation: slide-down .2s ease 1;
      max-width: 640px;
      width: 100%;
      z-index: $zindex-0;
    }
  }

  &.modal-sm {
    .modal-container {
      max-width: 320px;
      padding: 0 $unit-2;
    }
  }

  &.modal-lg {
    .modal-overlay {
      background: $bg-color-light;
    }

    .modal-container {
      box-shadow: none;
      max-width: 960px;
    }
  }
}

.modal-container {
  @include shadow-variant(.2rem);
  background: $bg-color-light;
  border-radius: $border-radius;
  display: block;
  padding: 0 $unit-4;
  text-align: left;

  .modal-header {
    padding: $unit-4;
  }

  .modal-body {
    max-height: 50vh;
    overflow-y: auto;
    padding: $unit-4;
    position: relative;
  }

  .modal-footer {
    padding: $unit-4;
    text-align: right;
  }
}
