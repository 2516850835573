@charset "utf-8";

$fa-font-path:    "../fonts/font-awesome";
$primary-color:   #000000;
$dark-color:      #000000;
$color-gris-noir: #2d3238;
$color-gris-gris: #6c6f73;

@import "spectre.css/src/spectre";
@import "font-awesome/scss/font-awesome";

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Work Sans Light'), local('WorkSans-Light'), url(/assets/fonts/WorkSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'), url(/assets/fonts/WorkSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Work Sans Medium'), local('WorkSans-Medium'), url(/assets/fonts/WorkSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Work Sans Bold'), local('WorkSans-Bold'), url(/assets/fonts/WorkSans-Bold.ttf) format('truetype');
}

body {
  font-family: "Work Sans";
  font-weight: 300;
}

html, body {
  height: 100%;
}

@media (min-width: 46em) {
  .container.main {
    max-width: 46rem;
  }
}

section.content {
  color: $color-gris-gris;
  h1,h2 {
    font-weight: 500;
    font-size: 1.5rem;
  }
  h3 {
    font-weight: 400; 
    font-size: 1rem;
  }
  h1, h2, h3 {
    color: $color-gris-noir;
  }
  li {
    margin-top: 0rem;
  }
}
.navbar-section .btn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar {
  padding-top: 2.4rem;
  padding-bottom: 2rem;
  a:hover {
    text-decoration: underline;
  }
}

.footer {
  .columns { max-width: 46rem; margin: 0 auto; }
  background-color: #262F36;
  color: #d9d9d9;
  small {
    font-weight: 300;
  }
  a { 
    color: #fff;
  }
  margin-top: 2rem;
  padding: 2rem;
}

.product-list {
  
  padding-top: 1.5rem;
  img {
    border: .05rem solid #e5e5e5;
    border-radius: 0.2rem;
  }
}

header h1 {
  text-transform: uppercase;
  color: #d9d9d9; 
  font-weight: 400; 
  font-size: 1.2rem;
}

.landing {
  margin-top: 6rem;
}

.card-title {
  color: $color-gris-noir;
  font-weight: 400; 
  font-size: 1rem;
}

.contact-us {
  margin-top: 1rem;
}