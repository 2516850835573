// Codes
code {
  @include label-base();
  @include label-variant($code-color, lighten($code-color, 33%));
  font-size: 85%;
}

.code {
  border-radius: $border-radius;
  color: $body-font-color;
  position: relative;

  &::before {
    color: $gray-color;
    content: attr(data-lang);
    font-size: $font-size-sm;
    position: absolute;
    right: $layout-spacing;
    top: $unit-h;
  }

  code {
    background: $bg-color;
    color: inherit;
    display: block;
    line-height: 1.5;
    overflow-x: auto;
    padding: 1rem;
    width: 100%;
  }
}
